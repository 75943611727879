import React from 'react';
import { URL_BASE, URL_ASSETS } from 'gatsby-env-variables';
import box_shaper_img from '../../images/blank_1000x1000.png';

const RelatedBookColumn = (props) => {
  const { id, main_image_url, status, name, author, chapters } = props.book;

  return (
    <div className="xl:col-span-3 lg:col-span-4 md:col-span-6 col-span-12 xl:text-justify text-center px-5 mb-5 xl:pt-0 pt-5 content-more-books">
      {status == 'Draft' ? null : (
        <a href={chapters[0] ? `/chapter/?id=${chapters[0]?.id}` : '#'}>
          <div className="thumbnail-cover" style={{ backgroundImage: `url(${URL_ASSETS}${main_image_url})` }}>
            <img src={box_shaper_img} className="thumbnail-placeholder" />
            <div className="description-more-books">{name ? <h6 className="font-opensans">{name}</h6> : null}</div>
          </div>
        </a>
      )}
    </div>
  );
};

export default RelatedBookColumn;
