import React from 'react';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import { useGetStories } from '../helper/services';
import MoreBookColumn from '@components/containers/MoreBookColumn';
import { SpinnerDotted } from 'spinners-react';

const StoriesPage = () => {
  const { stories, isLoading, error } = useGetStories();

  return (
    <LayoutPrivate pageTitle="Books">
      <section className="section-secondary relative w-full min-h-[100vh] bg-black">
        <div className="responsive-chapter py-20 mx-auto">
          <h1 className="text-5xl md:text-6xl lg:text-4xl  font-rokkit font-bold  text-start text-white mb-4">Stories</h1>
          <div className="grid grid-cols-12 gap-0 -mx-5 content-more-books">
            {isLoading ? (
              <SpinnerDotted size={20} thickness={180} speed={100} color="rgba(153, 127, 75, 1)" />
            ) : error || !stories ? (
              <div className="text-xxs text-white">No results</div>
            ) : (
              stories.map((book) => <MoreBookColumn book={book} key={book.id} />)
            )}
          </div>
        </div>
      </section>
    </LayoutPrivate>
  );
};

export default StoriesPage;
