import React, { useEffect } from 'react';
import { useAuth } from 'contexts/MainAppContext';
import { navigate } from 'gatsby';
import Layout from './Layout';

const LayoutPrivate = ({ children, ...props }) => {
  const { isUserLoggedIn, isUserInformationLoading } = useAuth();

  useEffect(() => {
    if (!isUserInformationLoading && !isUserLoggedIn) {
      console.log('LayoutPrivate: navigate to / with showLogin: true');
      navigate('/?showLogin=true');
    }
  }, [isUserLoggedIn, isUserInformationLoading]);

  return (
    <Layout showPrivateNavigation={true} {...props}>
      {children}
    </Layout>
  );
};

export default LayoutPrivate;
